const apiConfig = {
  baseURL: process.env.REACT_APP_BASEURL,
  endpoints: {
    auth:{
    login: '/auth/v1/login/password',
    logout: '/api/logout',
    },
    warehouse: {
    list: '/api/warehouse/info',
    details: '/api/warehouse/info/{warehouseId}',
    carts: '/api/warehouse/{warehouseId}/mandicart/list',
    },
    product: {
    add: '/api/warehouse/products',
    update: '/api/warehouse/products',
    seach: '/api/warehouse/products/search',
    filter: '/api/warehouse/products/filter',
    details: '/api/warehouse/products/{productId}',
    addVariation: '/api/warehouse/products/{productId}/variations',
    updateVariation: '/api/warehouse/products/variations/{variationId}',
    customer: '/api/warehouse/products/customers',
    },         
    category: {
    list: '/api/admin/categories',
    add: '/api/admin/categories',
    unassign: '/api/admin/categories/remove',
    assign: '/api/admin/categories/assign',
    fetchProduct: '/api/admin/categories/{categoryId}/products',
    delete: '/api/admin/categories/{categoryId}',
    },
    inventory: {
    list: '/api/warehouse/{warehouseId}/inventory',
    add: '/api/warehouse/{warehouseId}/inventory',
    credit: '/api/warehouse/{warehouseId}/inventory/{warehouseInventoryId}/credit',
    adjust: '/api/warehouse/{warehouseId}/inventory/{warehouseInventoryId}/adjust',
    statement: '/api/warehouse/{warehouseId}/inventory/statement',
    statementpdf: '/api/warehouse/{warehouseId}/inventory/statement/pdf',
    subcategory: '/api/warehouse/{warehouseId}/inventory/category',
    },
    order: {
    list: '/api/warehouse/order/{warehouseId}',
    orderDetails: '/api/warehouse/order/{warehouseId}/{orderId}',
    status: '/api/warehouse/order/{warehouseId}/{orderId}/status',
    fetch: '/api/warehouse/order/{warehouseId}/filter',
    itemApprove_old: '/api/warehouse/order/{warehouseId}/{orderId}/items/{itemId}',
    itemApprove: '/api/warehouse/order/approve/v1',
    summary: '/api/warehouse/order/summary-latest',
    },
    customer: {
    list: '/api/admin/customer',
    },
    sales: {
      customerSale: '/api/admin/customer/orders',
      customerSaleExport: '/api/admin/customer/orders/export',
      orderDetails: '/order/{orderId}',
      kartSale: '/api/admin/product/sales-report',
      kartSaleExport: '/api/admin/product/sales-report/export',
    },
    adminProduct: {
    list: '/api/admin/product/filter',
    update: '/api/admin/product/{productId}',
    delete: '/api/admin/product/{productId}',
    add: '/api/admin/product',
    addImage: '/api/admin/product/{productId}/image',
    deleteImage: '/api/file/PRODUCT/{fileId}',
    assignCategory: '/api/admin/categories/assign',
    unassignCategory: '/api/admin/categories/remove',
    viewCategoryProducts: '/api/admin/categories/{categoryId}/products',
    deleteCategory: '/api/admin/categories/{categoryId}',
    productVariation: '/api/admin/product/variation/filter',
    },  
    adminCart: {
    list: '/api/admin/mandikart/list',
    add: '/api/admin/mandikart',
    delete: '/api/admin/mandikart/{kartId}/warehouse',
    update: '/api/admin/mandikart/{kartId}',
    cartDetail: '/api/admin/mandikart/{kartId}',
    assignWarehouse : '/api/admin/mandikart/{kartId}/warehouse/{warehouseId}',
    changeStatus: '/api/admin/mandikart/{kartId}/status/{status}'
    },
    adminWarehouse: {
    list: '/api/admin/warehouse/list',
    add: '/api/admin/warehouse',
    update: '/api/admin/warehouse/{warehouseId}',
    createManager: '/api/admin/warehouse-admin',
    attachDoc: '/api/admin/warehouse-admin/{adminId}/image',
    setPassword: '/api/admin/warehouse-admin/{adminId}/secret',
    updateManager: '/api/admin/warehouse-admin/{adminId}',
    assignWarehouse: '/api/admin/warehouse-admin/{adminId}/assign-warehouse/{warehouse}',
    unassignWarehouse: '/api/admin/warehouse-admin/{adminId}/unassign-warehouse/{warehouse}',
    fetchSensitiveInfo: '/api/admin/warehouse-admin/{adminId}/sensitive',
    fetchAdminDetails: '/api/admin/warehouse-admin/{adminId}',
    managerList: '/api/admin/warehouse-admin/list',
    },
    adminVendor: {        
    list: '/api/admin/vendor',
    add: '/api/admin/vendor',
    update: '/api/admin/vendor/{vendorId}',
    assignKart: '/api/admin/vendor/{vendorId}/assign-kart/{kartId}',
    unassignKart: '/api/admin/vendor/{vendorId}/unassign-kart/{kartId}',
    uploadDoc: '/api/admin/vendor/{vendorId}/image',
    vendorAdharDetails: '/api/admin/vendor/{vendorId}/sensitive',
    vendorDetails: '/api/admin/vendor/{vendorId}',
    },
    adminOffer: {
    list: '/offers',
    addCart: '/offers/cart-value',
    addSpecial: '/offers/special-price',
    changeStatus: '/offers/{offerId}/status',
    offerDetails: '/offers/{offerId}',
    fetchActiveOffer: '/offers/active'
    }
    // Add other endpoints here
  },
  };
  
  export default apiConfig;
